// callback on async load of jQuery.Cycle2
var initCarousels;

// carousel settings
var Carousel;

// ui helpers
var Ui;

(function ($) {
  
  initCarousels = function(){
    Carousel.init('.block-carousel, .block-carousel-with-thumbnails');
  };

  Carousel = {

    $carousels: {},
    
    defaults: {
      auto: true,             // Boolean: Animate automatically, true or false
      speed: 500,            // Integer: Speed of the transition, in milliseconds
      timeout: 4000,          // Integer: Time between slide transitions, in milliseconds
      pager: true,           // Boolean: Show pager, true or false
      nav: false,             // Boolean: Show navigation, true or false
      random: false,          // Boolean: Randomize the order of the slides, true or false
      pause: false,           // Boolean: Pause on hover, true or false
      pauseControls: true,    // Boolean: Pause when hovering controls, true or false
      prevText: "Previous",   // String: Text for the "previous" button
      nextText: "Next",       // String: Text for the "next" button
      maxwidth: "",           // Integer: Max-width of the slideshow, in pixels
      navContainer: "",       // Selector: Where controls should be appended to, default is after the 'ul'
      manualControls: "",     // Selector: Declare custom pager navigation
      namespace: "rslides",   // String: Change the default namespace used
      before: function(){},   // Function: Before callback
      after: function(){}     // Function: After callback
    },

    init: function(selector, options = false) {
      var self = this;
      var $carousels = $(selector);
      $carousels.each(function(){
        // default RS options
        var rs_options = options || self.defaults;
        // store carousel element
        rs_options.$carousel = this;
        // if carousel with thumbnails, add manual controls option
        if($(this).hasClass('block-carousel-with-thumbnails')) {
          rs_options.auto = false;
          rs_options.nav = true;
          rs_options.prevText = "";
          rs_options.nextText = "";
          rs_options.manualControls = '.carousel-thumbnails ul';
          rs_options.namespace = 'rslides_thumbs';
          rs_options.before = function(idx){ 
            var $counter = $(this.$carousel).find('.carousel-counter-index');
            $counter.text(idx + 1);
          };
        } else {
          // resize images 
          // *note*: the dimensions of the inner element wrapping the slides, 
          // .carousel-slides, cannot be safely determined!
          self.resize($(this).find('.carousel-wrapper'));
          // listen for window resize
          self.addListener($(this).find('.carousel-wrapper'));
        }
        // get slides
        var $slides = $(this).find('.carousel-slides');
        // start the slideshow
        $slides.responsiveSlides(rs_options);
      });
    },

    resize: function($carousel){
      var vw_ratio;
      var w = $carousel.width();
      var h = $carousel.height();

      if (w && h) {
        // ratio of the slideshow container
        vw_ratio = w / h;
//        console.log('viewport ratio: ' + vw_ratio);
        $carousel.find('img').each(function(index){
          // natural ratio of the image
          var ratio = this.naturalWidth / this.naturalHeight;
//          console.log('img ratio: ' + ratio);
          // if the image is wider, set height to 100%
          if(ratio > vw_ratio) {
            $(this).css('height', '100%');
            $(this).css('width', 'auto');
            $(this).css('min-width', 'auto');
          } 
          // if the image is narrower, set width to 100%
          else {
            $(this).css('width', '100%');
            $(this).css('height', 'auto');
            $(this).css('min-height', 'auto');
          }
        });
      }
    },

    addListener: function($carousel){
      var self = this;
      $(window).resize(function(){
        self.resize($carousel);
      });
    }

  };

  Ui = {
    setScrollNav: function($links) {
      if($.scrollTo != null) {
        $links.each(function(){
          $(this).click(function(e){
            var href = '#' + this.href.split('#').pop();
            e.preventDefault();
            $(window).scrollTo($(href), 400);
          });
        });
      }
    }
  }


  $(document).ready(function(){
    $('.combined-page .submenu').prependTo('.main-navigation');
    Ui.setScrollNav($('.submenu a'));
  });

}(jQuery));


